
.grido1{
    padding-top: 24px!important;
}

.grido2{
    padding-top: 8px!important;
}

.altrimod2{
    flex-basis: 25%!important;
}

@media (max-width:600px)   {
.altrimod2{
    flex-basis: 50%!important;
   
}
}

/* .MuiAutocomplete-option[aria-selected="true"] .Mui-focused{
    background-color: black!important;
} */

.botonAgrego{
    color: #004e56!important;
    font-weight: 500!important;
}

.botonAgrego:hover{
    color: #004e56!important;
    color: #49a4ad!important;
    background-color: transparent!important;
    font-weight: 500!important;
}

/* .Mui-disabled{
    background-color: #fff!important;
  
      
  }
   */